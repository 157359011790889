


























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  mdiAccountOutline,
  mdiAccountSwitchOutline,
  mdiCurrencyRub,
  mdiFormatListChecks,
  mdiLogoutVariant,
  mdiSettingsHelper
} from "@mdi/js";
import { IconsType } from "@/types";
import Authentication from "@/services/Authentication";
const UserModule = namespace("UserModule");
const AppModule = namespace("AppModule");
@Component
export default class Settings extends Vue {
  @Prop(Boolean) readonly dark!: boolean;
  @AppModule.Getter
  public smallScreen!: string;
  @UserModule.Getter
  public getPoints!: string;
  @UserModule.Getter
  public getName!: string;
  @UserModule.State
  public role!: string;
  get isAdmin() {
    return this.role === "admin";
  }
  get isInAdminView() {
    return this.$route.name === "Admin";
  }
  get color() {
    return this.dark ? "rgb(255,255,255)" : "rgba(0,0,0,0.54)";
  }
  get getSwitchUserText() {
    return this.$t(
      this.isInAdminView ? "switch-account-admin" : "switch-account-user"
    );
  }
  private icons: IconsType = {
    rub: mdiCurrencyRub,
    settings: mdiSettingsHelper,
    account: mdiAccountOutline,
    applications: mdiFormatListChecks,
    logout: mdiLogoutVariant,
    switch: mdiAccountSwitchOutline
  };
  public async onMyApplications() {
    this.$router.push({ name: "Profile" });
  }
  public async onSignOut() {
    await Authentication.logout();
    this.$store.commit("AppModule/RESIZE");
  }
  public switchAccount(): void {
    if (this.$route.name === "Admin") this.$router.push("/");
    else this.$router.push({ name: "Admin" });
  }
}
